// Modules exported here will be included in bundle.js
import URI from 'urijs';
export { URI };
export { redirectPage, originIsOk } from './iframe-common';

/**
 * Card Click for CTAs
 * @param {*} opts data to send to pages
 */
const onCardClickCTA = (opts) => {
  if (window.parent && window.parentIFrame) {
    window.parent.postMessage(opts, '*');
  } else {
    console.log('would trigger event in parent:', opts);
  }
};

/**
 * Scroll to top of parent page
 */
const scrollUp = () => {
  if (window.onCardClickCTA) {
    onCardClickCTA({ label: 'Scroll To Top' });
  }
};

window.onCardClickCTA = onCardClickCTA;
window.scrollUp = scrollUp;

/**
 * Figures out if CTA Text is a Modal Popper
 * @param {string} ctaText
 */
export const isModal = (ctaText) => {
  return [
    'Schedule Appointment',
    'Contact Us',
    'Mattress Shipping Quote',
    'Request Quote',
    'Reserve Storage',
    'Auction Shipping Quote',
  ].includes(ctaText);
};
