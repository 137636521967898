/**
 * The formatters-custom file is a place to define custom formatters or to
 * override any of the baked-in formatters like phone number formatting.
 *
 * e.g. You may write something like below
 * export function address(profile) {
 *   return `111 Yext Street`;
 * }
 */
 import HoursTransformer from '../../../themes/answers-hitchhiker-theme/static/js/hours/transformer.js';
 import HoursStringsLocalizer from '../../../themes/answers-hitchhiker-theme/static/js/hours/stringslocalizer.js';
 import { PickupHoursTableBuilder }  from './PickupHoursTableBuilder.js';
 import { StandardHoursTableBuilder } from './StandardHoursTableBuilder.js';
 import { DayNames } from '../../../themes/answers-hitchhiker-theme/static/js/hours/constants.js';
 import { OpenStatusTypes } from '../../../themes/answers-hitchhiker-theme/static/js/hours/open-status/constants.js';

 export function getDirectionsUrl(profile, key = 'address') {
    const addr = profile[key];
    if (!addr) {
      return '';
    }

    if(profile.c_nGoogleMyBusinessURL) {
      return profile.c_nGoogleMyBusinessURL;
    }
  
    const line2 = addr.line2 ? ` ${addr.line2},` : ``;
    const region = addr.region ? ` ${addr.region}` : ``;
    const rawQuery = `${addr.line1},${line2} ${addr.city},${region} ${addr.postalCode} ${addr.countryCode}`;
    const query = encodeURIComponent(rawQuery);
  
    let url = `https://www.google.com/maps/search/?api=1&query=${query}&output=classic`;
  
    if (profile.googlePlaceId) {
      url += `&query_place_id=${profile.googlePlaceId}`;
    }
  
    return url;
}

export function addTrackingParamsToURL(urlString) {
  if (!urlString) {return urlString;}
  if (!urlString.includes('locations.theupsstore.com')) {return urlString;}

  let url;
  try {
    url = new URL(urlString);
  } catch {
    console.log(`${urlString} is not a valid URL, cannot add tracking parameters.`);
    return urlString;
  }
  
  url.search = '?utm_source=Yext&utm_medium=Answers&utm_campaign=Answers';
  return url.toString();
}

/**
 * Modified from hoursList from formatters-internal.js.
 * Modifies pickup hours field to a format usable by HoursTransformer and passes it into a custom table builder
 * Returns the markup for a formatted pickup hours list for the given field on the profile.
 *
 * @param {Object} profile The profile information of the entity
 * @param {Object} opts
 * {
 *   isTwentyFourHourClock {@link boolean} Use 24 hour clock if true, 12 hour clock if
 *                                         false. Default based on locale if undefined.
 *   disableOpenStatus: {@link boolean}   If specified, displays the hours intervals
 *                                      rather than the open status string for today
 *   firstDayInList: {@link string} A day name in English, e.g. "SUNDAY", this day will be
 *                                  displayed first in the list
 * }
 * @param {String} key Indicates which profile property to use for hours
 * @param {String} locale The locale for the time string
 */
 export function pickupHoursList(profile, opts = {}, key, locale) {
  if (!profile[key]) {
    return '';
  }

  const hours = HoursTransformer.transform(_formatPickupHours(profile[key], profile), profile.timeZoneUtcOffset);
  if (!hours) {
    return '';
  }

  const firstDayInList = opts.firstDayInList && opts.firstDayInList.toUpperCase();
  const isDayValid = Object.values(DayNames).includes(firstDayInList);
  if (firstDayInList && !isDayValid) {
    console.warn(`Invalid day: "${opts.firstDayInList}" provided as "firstDayInList" for the hoursList formatter`);
  }
  const standardizedOpts = {
    disableOpenStatus: true,
    firstDayInList: isDayValid && firstDayInList
  };

  const hoursLocalizer = new HoursStringsLocalizer(
    locale || _getDocumentLocale(), opts.isTwentyFourHourClock);
  return new PickupHoursTableBuilder(hoursLocalizer).build(hours, standardizedOpts);
}

/**
 * Modified from openStatus in formatters-internal.js
 * Customizes function to display pickup-related text instead of store open text
 * @param {Object} profile The profile information of the entity
 * @param {String} key Indicates which profile property to use for hours
 * @param {boolean} isTwentyFourHourClock Use 24 hour clock if true, 12 hour clock
 *                  if false. Default based on locale if undefined.
 * @param {String} locale The locale for the time string
 */
 export function pickupStatus(profile, key, isTwentyFourHourClock, locale) {
  if (!profile[key]) {
    return '';
  }

  const hours = HoursTransformer.transform(_formatPickupHours(profile[key], profile), profile.timeZoneUtcOffset);
  if (!hours) {
    return '';
  }

  const hoursLocalizer = new HoursStringsLocalizer(
    locale || _getDocumentLocale(), isTwentyFourHourClock);
  
  let time, day;
  switch (hours.openStatus.status) {
    case OpenStatusTypes.OPENS_TODAY:
    case OpenStatusTypes.CLOSES_TODAY:
      time = hoursLocalizer.getLocalizedTime(hours.openStatus.nextTime);
      return `
            <span class="Hours-statusText Hours-opensToday">
              <span class="Hours-statusText--current">
                Last Pickup Today at ${time}
              </span>
            </span>`;
    case OpenStatusTypes.OPENS_NEXT:
      time = hoursLocalizer.getLocalizedTime(hours.openStatus.nextTime);
      day = hoursLocalizer.getTranslation(hours.openStatus.nextDay);
      let opensTomorrow = false;
      for (let i = 0; i < 7; i++) {
        if (hours.days[i].day == hours.today.day) {
          if (hours.days[(i + 1) %7] == hours.openStatus.nextDay) opensTomorrow = true;
        }
      }
      let message = opensTomorrow ? `Next Pickup Tomorrow at ${time}` : `Next Pickup ${day} at ${time}`;
      return `
            <span class="Hours-statusText Hours-opensNext">
              <span class="Hours-statusText--current">
                ${message}
              </span>
            </span>`;
    default:
      return '';
  }
}

export function _formatPickupHours(hours, profile) {
  const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

  if (!hours || !profile.hours) {
    return '';
  }

  let formattedStoreHours = HoursTransformer.transform(profile.hours, profile.timeZoneUtcOffset);

  let hoursFormatted = {
    'monday': '',
    'tuesday': '',
    'wednesday': '',
    'thursday': '',
    'friday': '',
    'saturday': '',
    'sunday': '',
  };

  for (let day in hours) {
    // We need to make closing time 1 minute after last pickup time
    // for the component's "Open Today" hours component to function correctly

    let dayIdx = days.indexOf(day)
    let isHoliday = (day == 'holidayTimes') || (formattedStoreHours.days[dayIdx] && formattedStoreHours.days[dayIdx].dailyHolidayHours && formattedStoreHours.days[dayIdx].dailyHolidayHours.isClosed);

    if (isHoliday || profile.hours[day].isClosed) {
        // store is closed so dont set pick up times
        continue
    }

    let hoursString = hours[day].substring(0, 2);
    let minutesString = hours[day].slice(-2);
    // Handle carrying logic
    // Note: it's no problem if 23:59 overflows to 00:00 because our component is wired to handle it
    if (minutesString == "59") {
      if (hoursString.substring(0, 2) == "23") {
        hoursString = "00";
      } else {
        hoursString = (parseInt(hoursString) + 1);
      }
      minutesString == "00";
    } else {
      minutesString = minutesString == 0 ? "01" : (parseInt(minutesString) + 1);
    }

    let closingTime = hoursString + ":" + minutesString;

    hoursFormatted[day] = {
      'openIntervals':
        [
          {
            'start': hours[day],
            'end': closingTime
          }
        ],
      'intervals':
        [
          {
            'start': hours[day],
            'end': closingTime
          }
        ],
    }
  }

  for (let day in hoursFormatted) {
    if (hoursFormatted[day] == '') {
      hoursFormatted[day] = {
        'openIntervals': [],
        'intervals': [],
        'isClosed': true
      }
    }
  }

  return hoursFormatted;
}

export function _getDocumentLocale() {
  return document.documentElement.lang.replace('_', '-');
}

/**
 * Overriding this function to pass through holiday dates and names to display in hours table
 * Returns the markup for a formatted hours list for the given field on the profile.
 *
 * @param {Object} profile The profile information of the entity
 * @param {Object} holidayList list of dates with associated holiday names
 * @param {Object} opts
 * {
 *   isTwentyFourHourClock {@link boolean} Use 24 hour clock if true, 12 hour clock if
 *                                         false. Default based on locale if undefined.
 *   disableOpenStatus: {@link boolean}   If specified, displays the hours intervals
 *                                      rather than the open status string for today
 *   firstDayInList: {@link string} A day name in English, e.g. "SUNDAY", this day will be
 *                                  displayed first in the list
 * }
 * @param {String} key Indicates which profile property to use for hours
 * @param {String} locale The locale for the time string
 */
 export function standardHoursList(profile, holidayList, opts = {}, key = 'hours', locale) {
  if (!profile[key]) {
    return '';
  }

  const hours = HoursTransformer.transform(profile[key], profile.timeZoneUtcOffset);
  if (!hours) {
    return '';
  }

  const firstDayInList = opts.firstDayInList && opts.firstDayInList.toUpperCase();
  const isDayValid = Object.values(DayNames).includes(firstDayInList);
  if (firstDayInList && !isDayValid) {
    console.warn(`Invalid day: "${opts.firstDayInList}" provided as "firstDayInList" for the hoursList formatter`);
  }
  const standardizedOpts = {
    disableOpenStatus: opts.disableOpenStatus || false,
    firstDayInList: isDayValid && firstDayInList
  };

  const hoursLocalizer = new HoursStringsLocalizer(
    _getLocaleWithDashes(locale) || _getDocumentLocale(), opts.isTwentyFourHourClock);
  return new StandardHoursTableBuilder(hoursLocalizer, holidayList).build(hours, standardizedOpts);
}

export function _getLocaleWithDashes(locale) {
  return locale && locale.replace(/_/g, '-');
}
