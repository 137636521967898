import HoursTableBuilder from '../../../themes/answers-hitchhiker-theme/static/js/hours/table/builder.js'
import { OpenStatusStrings } from '../../../themes/answers-hitchhiker-theme/static/js/hours/open-status/constants';
import OpenStatusMessageFactory from '../../../themes/answers-hitchhiker-theme/static/js/hours/open-status/messagefactory';

// Overrides HoursTableBuilder because we need to put holiday name next to interval if holiday exists
class StandardHoursTableBuilder extends HoursTableBuilder {
  constructor(localizer, holidayHoursList) {
    super(localizer);
    this._holidays = holidayHoursList;
  }
   /**
   * Returns the markup for all hours intervals specified
   *
   * @param {Object[]} hoursIntervals
   * @returns {string}
   */
  _buildIntervalsForDay(hoursIntervals, holidayName) {
    if (hoursIntervals.length === 0) {
      let closedText = this._localizer.getTranslation(OpenStatusStrings.CLOSED);
      if (holidayName) {
        return `
          <span class="c-hours-is-closed-holiday">
            ${closedText + ' ' + holidayName}
          </span>
        `
      } else {
        return closedText
      }
    }

    const holidayModifier = holidayName ? ' c-hours-is-open-holiday' : '';

    let intervalsHTML = '';
    for (const interval of hoursIntervals) {
      intervalsHTML += `
        <span class="c-hours-details-row-intervals-instance${holidayModifier}">
          ${this._getIntervalHTML(interval.start, interval.end)}
          ${holidayName}
        </span>`;
    }
    return intervalsHTML;
  }

/**
 * Returns the markup for the open status
 *
 * @param {Object} openStatus
 * @param {string} holidayName
 */
  _buildOpenStatusHTML(openStatus, holidayName) {
    const holidayModifier = holidayName ? ' c-hours-is-open-holiday' : '';
    return `
      <span class="c-hours-details-row-intervals-instance${holidayModifier}">
        <span class="c-hours-details-row-intervals-instance-open">
            ${new OpenStatusMessageFactory(this._localizer).create(openStatus)}
            ${holidayName}
        </span>
      </span>`;
  }

  /**
   * Returns the content for the body of the hours table
   *
   * @param {Hours} hours
   * @param {Object} config
   * @returns {string}
   */
  _buildTableBodyContent(hours, config) {
    let tableBodyHTML = '';

    const sortedDays = hours.getSortedDaysStartingFrom(config.firstDayInList || hours.today.day);
    for (const [i, day] of sortedDays.entries()) {
      tableBodyHTML += this._buildTableRow(day, hours, config.disableOpenStatus, i);
    }

    return tableBodyHTML;
  }

    /**
   * Returns the markup for a table row (<tr>) for the specified day.
   * Passes through index so we can get the date for each new day relative to the current day
   *
   * @param {Object} day
   * @param {Hours} hours
   * @param {boolean} shouldDisableOpenStatus
   * @param {int} index
   * @returns {string}
   */
  _buildTableRow(day, hours, shouldDisableOpenStatus, index) {
    const isCurrentDayOfWeek = day.day == hours.today.day;

    let classes = '';
    if (day.dailyHolidayHours) {
      classes += ' is-holiday';
    }

    if (isCurrentDayOfWeek) {
      classes += ' is-today';
    }

    const holidayHours = day.dailyHolidayHours || {};
    const hoursIntervals = !holidayHours.isRegularHours && holidayHours.intervals
      ? holidayHours.intervals
      : day.intervals;
    const shouldShowOpenStatusMessage = isCurrentDayOfWeek
      && !shouldDisableOpenStatus
      && hoursIntervals.length === 1;

    // Determines if there are any holidays on the day
    const hoursDayDate = new Date();
    hoursDayDate.setDate(hoursDayDate.getDate() + index)
    const hoursDayDateString = hoursDayDate.toDateString()
    const holidayHoursForDate = this._holidays.find(holiday => {
      const holidayDate = new Date(holiday.date + 'T00:00:00.000');
      return hoursDayDateString == holidayDate.toDateString();
    });

    let holidayName = '';
    if (holidayHoursForDate?.holiday) {
      holidayName = '(' + holidayHoursForDate.holiday + ')'
    }

    return `
      <tr class="c-hours-details-row${classes}" data-index="${index}">
        <td class="c-hours-details-row-day">
          ${this._localizer.getTranslation(day.day)}
        </td>
        <td class="c-hours-details-row-intervals">
          ${(shouldShowOpenStatusMessage
              ? this._buildOpenStatusHTML(hours.openStatus, holidayName)
              : this._buildIntervalsForDay(hoursIntervals, holidayName))}
        </td>
      </tr>`;
  }
}

export { StandardHoursTableBuilder };