import HoursTableBuilder from '../../../themes/answers-hitchhiker-theme/static/js/hours/table/builder.js'

// Overrides HoursTableBuilder because last pickup hours table only needs one time per row
class PickupHoursTableBuilder extends HoursTableBuilder {
     _getIntervalHTML(startTime, endTime) {
      const isOpenAllDay = startTime == 0 && endTime == 2359;
      if (isOpenAllDay) {
        return this._localizer.getTranslation(OpenStatusStrings.OPEN_24_HOURS);
      }
  
      //startTime and endTime will always be the same for last pickup hours tables
      return `
        <span class="c-hours-details-row-intervals-instance-open">
          ${this._localizer.getLocalizedTime(startTime)}
        </span>`
    }

    _buildIntervalsForDay(hoursIntervals) {
        if (hoursIntervals.length === 0) {
            return "No Pick Up";
        }

        let intervalsHTML = '';
        for (const interval of hoursIntervals) {
            intervalsHTML += ` 
                <span class="c-hours-details-row-intervals-instance">
                    ${this._getIntervalHTML(interval.start, interval.end)}
                </span>`;
        }
        return intervalsHTML;
    }
}

export { PickupHoursTableBuilder };